import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../components/layout/Layout';

const TermsOfSalesPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          legalNotice {
            website
            websiteUrl
            owner {
              name
              legalStatus
              address
              email
              telephone
              telephoneIndex
              website
              websiteUrl
              siret
            }
            creator {
              name
              legalStatus
              address
              email
              telephone
              telephoneIndex
              website
              websiteUrl
              siret
            }
            publicationManager {
              name
              legalStatus
              address
              email
              telephone
              telephoneIndex
              website
              websiteUrl
              siret
            }
            webmaster {
              name
              legalStatus
              address
              email
              telephone
              telephoneIndex
              website
              websiteUrl
              siret
            }
            host {
              name
              legalStatus
              address
              email
              telephone
              telephoneIndex
              website
              websiteUrl
              siret
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/conditions-generales-de-vente-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Conditions générales de vente | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`Les conditions générales de vente de ${query.site.siteMetadata.title}. Retrouvez toutes les informations ici.`}
      pageKeywords="moussé gava, roanne, riorges, loire, travaux, plâtrerie, peinture, devis, prix, délais, paiements"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Conditions générales de vente</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Conditions générales de vente</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="px-5">
              <div className="mb-5">
                <h3>1. Devis.</h3>
                <p>Les prix du présent devis sont valables deux mois et révisables sauf convention contraire.</p>
              </div>
              <div className="mb-5">
                <h3>2. Prix.</h3>
                <p>Les prix mentionnés s’entendent nets, sans escompte et hors taxes pour l’exécution totale de la masse des travaux prévus.</p>
                <p>Les taxes seront facturées en sus au taux en vigueur lors de l’exécution des travaux.</p>
                <p>Le marché est traité sur la base des prix en vigueur à la date de commencement des travaux tels qu’ils résultent de l’actualisation des prix de l’offre de l’entreprise.</p>
                <p>
                    <strong>– Actualisation des prix :</strong> P = Po[a (…) + b (…) + c (…) etc.]<br/>
                    P étant le prix actualisé – Po le prix initial du devis<br/>
                    (…) les points de suspension correspondent aux différentes catégories d’ouvrage plâtrerie-peinture ou aux différents index publiés par le ministère de l’Équipement.<br/>
                    a, b, c, etc., correspondent au pourcentage de ces catégories d’ouvrage suivant la nature du chantier considéré. L’actualisation de nos prix ne sera pas appliqué sur l’acompte contractuel versé à la commande.
                </p>
                <p>
                  <strong>– Révisions des prix –</strong> 
                  En cas de variation économique imprévue en cours de travaux, la révision des prix sera conforme à l’Arrêté n° 74-22 P du 16 mai 1974.
                </p>
              </div>
              <div className="mb-5">
                <h3>3. Délais.</h3>
                <p>
                  Les délais donnés dans nos devis ne le sont qu’à titre indicatif. Les travaux supplémentaires allongent d’autant la date de livraison.<br/>
                  Nous serons dégagés de tout engagement de délai :<br/>
                  – dans le cas où les conditions de paiement prévues n’auraient pas été observées;<br/>
                  – dans le cas où les renseignements nécessaires à l’exécution des travaux n’auraient pas été fournis en temps utile;<br/>
                  – dans la cas où l’avancement des travaux des corps d’état nous précédant ne permettrait pas l’exécution des nôtres.
                </p>
                <p>Les cas de force majeure ou d’imprévus dégagent notre responsabilité.</p>
              </div>
              <div className="mb-5">
                <h3>4. Travaux supplémentaires.</h3>
                <p>Dans les cas de changements dans la masse des travaux, les ouvrages supplémentaires seront facturés en prenant pour base les prix unitaires du devis, ou à défaut les prix en vigueur dans le département de la Loire, affectés des coefficients correspondants au mois d’exécution.</p>
              </div>
              <div className="mb-5">
                <h3>5. Paiement (Commande-situation-facture).</h3>
                <p>
                  – Tous paiements devront s’effectuer au Siège Social de l’Entreprise et ce, quelles que soient les clauses portées sur les commandes qui nous seront remises.<br/>
                  – Les paiements devront être effectués comme suit :<br/>
                  • 30 % à la commande.<br/>
                  • 60 % en cours de travaux sur situations mensuelles payables dans les 15 jours suivant présentation.<br/>
                  • Le solde, a réception de la facture.<br/>
                  <strong> • Aucun escompte pour paiement anticipé ne pourra être pratiqué sans notre accord.</strong><br/>
                  <strong> • Toute facture non payée à la date convenue, sera majorée d’une pénalité au moins égale à 1,5 fois le taux d’intérêt légal sur les sommes restant dûes.</strong><br/>
                  <strong> • Une indemnité forfaitaire de 40 euros pour frais de recouvrement sera due en cas de retard de paiement. Si les frais de recouvrement réellement engagés sont supérieurs à ce montant forfaitaire, notamment en cas de recours à un cabinet chargé des relances et mises en demeure, une indemnisation complémentaire sur justification peut être demandée.</strong><br/>
                  <strong> • NOTA : Sans préjudice de tout recouvrement de frais bancaires ou agios que nous aurions à supporter du fait de paiements différés.</strong><br/>
                  – En cas de non-respect des conditions de paiement ci-dessus stipulées, nos travaux seront interrompus de plein droit. Les intérêts moratoires seront dus par le client de plein droit et sans mise en demeure préalable à un taux supérieur de deux points aux taux des avances sur titres de la Banque de France depuis la date à laquelle le paiement devait intervenir jusqu’à celle du paiement effectif.
                </p>
              </div>
              <div className="mb-5">
                <h3>6. Litiges.</h3>
                <p>En cas de contestation de quelque nature qu’elle soit les différends seront réglés par arbitrage de l’Office du Bâtiment et des Travaux Publics de la Loire. Quant aux litiges qui ne seraient pas réglés par arbitrage, ils seront portés devant les Tribunaux du Siège Social de l’entreprise, même s’il y a pluralités de défenseurs ou appel en garantie. L’acceptation de règlement n’opère ni changement, ni novation, ni dérogation à cette clause attributive de juridiction.</p>
              </div>
              <div className="mb-5">
                <h3>7. Réclamations.</h3>
                <p>
                  Les réclamations ne pourront être prises en considération que si elles sont formulées par courrier adressé à notre Siège au plus tard dans les 15 jours suivant la fin des travaux. Le fait par le client de n’avoir formulé aucune observation dans ce délai entraîne son acceptation pure et simple.<br/>
                  Dans les locaux inhabités, le fait de les occuper sera considéré comme acceptation et réception des travaux.
                </p>
              </div>
              <div className="mb-5">
                <h3>8. Clause pénale.</h3>
                <p>Une clause pénale forfaitaire de 10 % sera appliquée sur le montant de la facture non réglée à l’échéance.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TermsOfSalesPage
